/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import {
    Button, Col, Form, Input, Row, Select, Space, Spin, Typography, Grid,
    Checkbox,
    notification,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseOptionType } from 'antd/lib/select';
import { Key } from 'antd/lib/table/interface';
import { checkPackageParamValue, getRequiredFieldMessage, onInputWheel } from '../../helpers/form';
import { useCountries } from '../../hooks/useCountries';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useOrder } from '../../hooks/useOrder';
import { IOrder, IOrderPackageItem } from '../../types/order';
import { usePackageUnits } from '../../hooks/usePackageUnits';
import OrderItems, { IOrderItemsRef } from './items';
import { useOrderForm } from '../../hooks/useOrderForm';
import OrderPurchases, { IOrderPurchasesRef } from './purchases';
import { useWarehouses } from '../../hooks/useWarehouses';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';
import ChinaFlag from '../../assets/images/china.png';
import EuropeFlag from '../../assets/images/europe.svg';
import { useDeliveryTypes } from '../../hooks/useDeliveryTypes';
import { useParcelTypesForChina } from '../../hooks/useParcelTypesForChina';

const { useBreakpoint } = Grid;

export interface IMakeOrderStepsProps {
    goNextStep: () => void;
    onBackPress: () => void;
    canMoveBetweenSteps?: boolean;
}

export const linearLabels = {
    kg: {
        ru: 'кг.',
        de: 'kg.',
        en: 'kg.',
    },
    cm: {
        ru: 'см.',
        de: 'cm.',
        en: 'cm.',
    },
};

function MakeOrderFirstStep({ goNextStep, canMoveBetweenSteps }: IMakeOrderStepsProps) {
    const order = useOrder();
    const params = useParams<{ type: string; }>();

    const type = order.data?.type || params.type || '';

    const fetch = useFetch();
    const [form] = useForm();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { availableCountries } = useCountries({ canFetch: type.length > 0, orderType: type });
    const packageUnits = usePackageUnits(true);
    const orderItemsRef = useRef<IOrderItemsRef>();
    const orderPurchasesRef = useRef<IOrderPurchasesRef>();
    const { md } = useBreakpoint();
    const { availableFormItems, loading: availableFormItemsLoading } = useOrderForm(false);
    const warehouses = useWarehouses();
    const [fromCountry, setFromCountry] = useState<string>();
    const [warehouseId, setWarehouseId] = useState<number>();
    const [continent, setContinent] = useState<'china'|'europe'>();
    const { getFieldMandatory, selectOpen, watchMode } = useFormWatchMode();
    const dt = useDeliveryTypes(order.data?.id);
    const dtTimeout = useRef<NodeJS.Timeout>();
    const parcelTypesForChina = useParcelTypesForChina();
    const [insurance, setInsurance] = useState(false);
    const purchases = useRef<Key[]>([]);

    const langTwoLetters = i18n.language.slice(0, 2).toLowerCase() as 'ru' | 'de' | 'en';

    const getPackageUnitsFieldsValue = () => ({
        weight_unit: order.data?.package?.weight_unit || packageUnits.data?.weight[0].value,
        width_unit: order.data?.package?.width_unit || packageUnits.data?.linear[0].value,
        length_unit: order.data?.package?.length_unit || packageUnits.data?.linear[0].value,
        height_unit: order.data?.package?.height_unit || packageUnits.data?.linear[0].value,
    });

    // const recipientCountriesFiltered = useMemo(() => {
    //     if (fromCountry === 'CN') {
    //         return recipientCountries.data.filter((el) => el.value === 'RU');
    //     }
    //     return recipientCountries.data;
    // }, [fromCountry, recipientCountries.data, i18n.language]);

    useEffect(() => {
        if (order.data instanceof Object) {
            form.setFieldsValue({
                ...order.data, ...order.data?.package, ...getPackageUnitsFieldsValue(),
            });
            setContinent(order.data.from === 'CN' ? 'china' : 'europe');
            dt.getData();
            setInsurance(order.data.insurance);
            purchases.current = order.data.purchases;
            if (typeof order.data.warehouse_id === 'number') {
                setWarehouseId(order.data.warehouse_id);
            }
        } else {
            form.resetFields();
            form.setFieldsValue(getPackageUnitsFieldsValue());
            purchases.current = [];
        }
    }, [order.data, packageUnits.data]);

    useEffect(() => {
        orderPurchasesRef.current?.setSelectedRowKeys(order.data?.purchases || []);
        setFromCountry(order.data?.from);
    }, [order.data]);

    useEffect(() => {
        setWarehouseId(typeof order.data?.warehouse_id === 'number' ? order.data.warehouse_id : undefined);
    }, [order.data?.warehouse_id]);

    useEffect(() => {
        if (typeof warehouseId === 'number' && warehouses.data && warehouses.data?.list.length > 0) {
            const selectedWarehouse = warehouses.data?.list.find((el) => el.id === warehouseId);
            setFromCountry(selectedWarehouse?.country_code);
        }
    }, [warehouseId, warehouses.data?.list?.length]);

    useEffect(() => {
        if (fromCountry === 'CN') {
            form.setFieldValue('to', 'RU');
        }
    }, [fromCountry]);

    const processOrderPackage = (orderId: number, values: Record<string, any>) => fetch.request({
        url: order.data?.package instanceof Object ? `/order-packages/${order.data?.package.id}/update` : '/order-packages/create',
        data: {
            ...values,
            order_id: orderId,
        },
        method: 'post',
    });

    const processOrderPackageItems = (orderId: number) => fetch.request<IOrderPackageItem[]>({
        url: `/orders/${orderId}/items/save`,
        data: orderItemsRef.current?.getItems(),
        method: 'post',
    });

    const processOrderPurchases = (orderId: number) => fetch.request<IOrderPackageItem[]>({
        url: '/purchases/attach-to-order',
        data: {
            order_id: orderId,
            purchases: orderPurchasesRef.current?.selectedRowKeys,
        },
        method: 'post',
    });

    const onFinish = async (values: Record<string, any>) => {
        const body = {
            ...values,
            type,
            from: values.from || fromCountry,
        };

        if (type === 'marketplaces' && orderPurchasesRef.current?.selectedRowKeys.length === 0) {
            notification.warning({ message: t('selectPurchasesError') });
            return;
        }

        const result = await fetch.request<IOrder>({
            url: typeof order.data?.id !== 'undefined' ? `/orders/${order.data.id}/update` : '/orders/create',
            data: body,
            method: 'post',
        });
        if (result.success && result.data) {
            let canGoNextStep = true;

            if (availableFormItems.order_package?.types.includes(type)) {
                const opResult = await processOrderPackage(result.data.id, values);
                if (opResult.success) {
                    const x = await processOrderPackageItems(result.data.id);
                    if (!x.success) {
                        canGoNextStep = false;
                    }
                } else {
                    canGoNextStep = false;
                }
            }

            if (availableFormItems.order_purchases?.types.includes(type)) {
                const x = await processOrderPurchases(result.data.id);
                if (!x.success) {
                    canGoNextStep = false;
                }
            }

            await order.update(result.data.id);

            if (canGoNextStep && canMoveBetweenSteps && typeof order.data?.id === 'number') {
                await goNextStep();
            }

            if (typeof order.data?.id === 'undefined') {
                navigate(`/order/${result.data.id}`);
            }
        }
    };

    const onContinentSelect = (val: typeof continent) => {
        switch (val) {
            case 'china': {
                form.setFieldValue('from', 'CN');
                setFromCountry('CN');
                break;
            }
            case 'europe': {
                form.setFieldValue('from', undefined);
                setFromCountry(undefined);
                break;
            }
        }
        setContinent(val);
    };

    const onSelectFilterOption = (input: string, option?: BaseOptionType) => (option?.label?.toString().toLocaleLowerCase() ?? '').includes(input.toLowerCase());

    const onValuesChange = (changedValues: any, values: any) => {
        clearTimeout(dtTimeout.current);
        dtTimeout.current = setTimeout(() => {
            if (changedValues instanceof Object) {
                const keys = Object.keys(changedValues);
                const packageParamChanged = keys.includes('from') || keys.includes('weight') || keys.includes('width') || keys.includes('length') || keys.includes('height');
                const packageParamsFilled = typeof values.from === 'string' && values.from.length > 0 && checkPackageParamValue(values.weight) && checkPackageParamValue(values.width) && checkPackageParamValue(values.length) && checkPackageParamValue(values.height);
                if (type === 'common' && (packageParamChanged || keys.includes('insurance')) && packageParamsFilled) {
                    dt.getDataByPackage(values);
                }
                if (type === 'marketplaces' && (keys.includes('purchases') || keys.includes('insurance'))) {
                    dt.getDataByPurchases({
                        insurance: values.insurance,
                        purchases: changedValues.purchases || purchases.current,
                    });
                }
            }
        }, 1000);
    };

    const onPurchasesRowSelect = (selectedKeys: Key[]) => {
        onValuesChange({ purchases: selectedKeys }, form.getFieldsValue());
        purchases.current = selectedKeys;
    };

    return (
        <Spin spinning={order.dataLoading || availableFormItemsLoading}>
            <Form aria-readonly={watchMode} form={form} initialValues={{ ...order.data, ...order.data?.package, to: order.data?.to || 'RU' }} layout="vertical" onFinish={onFinish} onValuesChange={onValuesChange}>
                <Row gutter={[30, 30]}>
                    {availableFormItems.from?.types?.includes(type) && (
                        <Col xs={24} md={12}>
                            <Space className="w-100" direction="vertical" size="large">
                                <Space align="center" className="w-100" direction="vertical">
                                    <div className="d-flex justify-content-center">
                                        <button disabled={watchMode} className="order-country-flag-btn" onClick={() => onContinentSelect('europe')} type="button">
                                            <img alt="Europe" className="order-country-flag-img" data-selected={continent === 'europe' && 'true'} src={EuropeFlag} />
                                        </button>
                                    </div>
                                    <span>{t('europe')}</span>
                                </Space>
                                <Form.Item
                                    className="ant-form-item-with-extra"
                                    label={t('country')}
                                    name="from"
                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('country')) }]}
                                    hidden={typeof continent === 'undefined' || continent === 'china'}
                                >
                                    <Select
                                        open={selectOpen}
                                        showSearch
                                        options={availableCountries.data}
                                        loading={availableCountries.loading}
                                        filterOption={onSelectFilterOption}
                                        onSelect={setFromCountry}
                                    />
                                </Form.Item>
                            </Space>
                        </Col>
                    )}
                    {availableFormItems.from?.types?.includes(type) && (
                        <Col xs={24} md={12}>
                            <Space align="center" className="w-100" direction="vertical">
                                <div className="d-flex justify-content-center">
                                    <button disabled={watchMode} className="order-country-flag-btn" onClick={() => onContinentSelect('china')} type="button">
                                        <img alt="China" className="order-country-flag-img" data-selected={continent === 'china' && 'true'} src={ChinaFlag} />
                                    </button>
                                </div>
                                <span>{t('china')}</span>
                            </Space>
                        </Col>
                    )}
                    {/* <Col xs={24} md={12}>
                            <Form.Item
                                className="ant-form-item-with-extra"
                                label={t('orderTo')}
                                name="to"
                                rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderTo')) }]}
                            >
                                <Select
                                    open={selectOpen}
                                    showSearch
                                    options={recipientCountriesFiltered}
                                    loading={recipientCountries.loading}
                                    filterOption={onSelectFilterOption}
                                />
                            </Form.Item>
                        </Col> */}
                    <Col xs={24}>
                        <Row gutter={[30, 30]}>
                            {continent === 'china' && type === 'common' && (
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        className="ant-form-item-with-extra ant-form-item-label-always-top"
                                        label={t('parcelType')}
                                        name="parcel_type_china"
                                        rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('deliveryType')) }]}
                                    >
                                        <Select
                                            open={selectOpen}
                                            options={parcelTypesForChina.data}
                                            loading={parcelTypesForChina.loading}
                                            filterOption={onSelectFilterOption}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            <Col xs={24}>
                                <Form.Item
                                    className="checkbox-form-item"
                                    name="insurance"
                                    valuePropName="checked"
                                >
                                    <Space>
                                        <Checkbox
                                            checked={insurance}
                                            onChange={watchMode ? undefined : () => setInsurance((val) => !val)}
                                        />
                                        <Button
                                            className="height-auto"
                                            type="link"
                                            onClick={watchMode ? undefined : () => {
                                                setInsurance((val) => !val);
                                                form.setFieldValue('insurance', !insurance);
                                                onValuesChange({ insurance: !insurance }, form.getFieldsValue());
                                            }}
                                        >
                                            {t('parcelInsurance')}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                            {availableFormItems.order_purchases?.types.includes(type) && (
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        className="ant-form-item-with-extra"
                                        label={t('warehouse')}
                                        rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('warehouse')) }]}
                                    >
                                        <Select
                                            open={selectOpen}
                                            value={warehouseId}
                                            loading={warehouses.loading}
                                            options={warehouses.selectOptions}
                                            onSelect={setWarehouseId}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    {availableFormItems.order_package?.types?.includes(type) && (
                        <>
                            <Col xs={24}>
                                <Space direction={md ? 'horizontal' : 'vertical'} size="large">
                                    <Row gutter={[12, 12]}>
                                        <Col xs={24}>
                                            <Typography.Title level={5}>
                                                {t('regularShipment')}
                                            </Typography.Title>
                                        </Col>
                                        <Col xs={24}>
                                            <Space direction="vertical">
                                                <span className="fw-400">
                                                    {t('maxLength')}
                                                    :
                                                    {' '}
                                                    {`105 ${linearLabels.cm[langTwoLetters]}`}
                                                </span>
                                                <span className="fw-400">
                                                    {t('maxWeight')}
                                                    :
                                                    {' '}
                                                    {`20.0 ${linearLabels.kg[langTwoLetters]}`}
                                                </span>
                                                <span className="fw-400">
                                                    {t('maxGirth')}
                                                    :
                                                    {' '}
                                                    {`200 ${linearLabels.cm[langTwoLetters]}`}
                                                </span>
                                            </Space>
                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]}>
                                        <Col xs={24}>
                                            <Typography.Title level={5}>
                                                {t('expressShipment')}
                                            </Typography.Title>
                                        </Col>
                                        <Col xs={24}>
                                            <Space direction="vertical">
                                                <span className="fw-400">
                                                    {t('maxLength')}
                                                    :
                                                    {' '}
                                                    {`150 ${linearLabels.cm[langTwoLetters]}`}
                                                </span>
                                                <span className="fw-400">
                                                    {t('maxWeight')}
                                                    :
                                                    {' '}
                                                    {`30.0 ${linearLabels.kg[langTwoLetters]}`}
                                                </span>
                                                <span className="fw-400">
                                                    {t('maxGirth')}
                                                    :
                                                    {' '}
                                                    {`300 ${linearLabels.cm[langTwoLetters]}`}
                                                </span>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                            <Col xs={24}>
                                <OrderItems ref={orderItemsRef} orderForm={form} />
                            </Col>
                        </>
                    )}
                    {availableFormItems.order_package?.types?.includes(type) && (
                        <>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageWeight')}
                                    name="weight"
                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackageWeight')) }]}
                                >
                                    <Input
                                        readOnly={watchMode}
                                        onKeyPress={(event) => {
                                            if (!/[0-9,.]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        suffix={packageUnits.data?.weight[0].label}
                                        onWheel={onInputWheel}
                                        min={0}
                                    />
                                </Form.Item>
                                <Form.Item hidden name="weight_unit" />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageWidth')}
                                    name="width"
                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackageWidth')) }]}
                                >
                                    <Input
                                        readOnly={watchMode}
                                        onKeyPress={(event) => {
                                            if (!/[0-9,.]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        type="number"
                                        suffix={packageUnits.data?.linear[0].label}
                                        onWheel={onInputWheel}
                                        min={0}
                                    />
                                </Form.Item>
                                <Form.Item hidden name="width_unit" />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageLength')}
                                    name="length"
                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackageLength')) }]}
                                >
                                    <Input
                                        readOnly={watchMode}
                                        onKeyPress={(event) => {
                                            if (!/[0-9,.]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        type="number"
                                        suffix={packageUnits.data?.linear[0].label}
                                        onWheel={onInputWheel}
                                        min={0}
                                    />
                                </Form.Item>
                                <Form.Item hidden name="length_unit" />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageHeight')}
                                    name="height"
                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackageHeight')) }]}
                                >
                                    <Input
                                        readOnly={watchMode}
                                        onKeyPress={(event) => {
                                            if (!/[0-9,.]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        type="number"
                                        suffix={packageUnits.data?.linear[0].label}
                                        onWheel={onInputWheel}
                                        min={0}
                                    />
                                </Form.Item>
                                <Form.Item hidden name="height_unit" />
                            </Col>
                        </>
                    )}
                    {availableFormItems.order_purchases?.types.includes(type) && (
                        <Col xs={24}>
                            <OrderPurchases
                                ref={orderPurchasesRef}
                                onRowSelect={onPurchasesRowSelect}
                                warehouseId={warehouseId}
                            />
                        </Col>
                    )}
                    {fromCountry && fromCountry !== 'CN' && (
                        <Col xs={24} md={12}>
                            <Form.Item
                                className="ant-form-item-with-extra ant-form-item-label-always-top"
                                label={t('deliveryType')}
                                name="delivery_type_id"
                                rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('deliveryType')) }]}
                            >
                                <Select
                                    disabled={dt.data.length === 0}
                                    open={selectOpen}
                                    options={dt.data}
                                    loading={dt.loading}
                                    filterOption={onSelectFilterOption}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col className="text-center" xs={24}>
                        <Button hidden={watchMode} type="primary" htmlType="submit" loading={fetch.loading}>
                            {canMoveBetweenSteps ? t('next') : t('update')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
}

export default MakeOrderFirstStep;
