export const deTerms = `
<p>
    1. Telega akzeptiert keine Fracht zum Transport, die nach eigenem Ermessen als gefährlich gilt und/oder die Fracht
    anderer Kunden schädigen kann, sowie Fracht, die Gegenstände enthält, die nach der Gesetzgebung des Ziellandes
    verboten sind. (Eine vollständige Liste solcher Verbote und Beschränkungen finden Sie unter
    <a href="https://eur-lex.europa.eu" target="_blank">https://eur-lex.europa.eu</a>)
</p>
<p>
    2. Bei Exportaufträgen garantiert der Versender, eine natürliche oder juristische Person, dass er die Ladung
    persönlich für den Transport oder die Erbringung anderer Dienstleistungen an einem sicheren Ort vorbereitet hat und
    dass die Vorbereitung der Ladung von einem dafür benannten Mitarbeiter des Versenders durchgeführt wurde und dass
    während der Vorbereitung, Lagerung und des Transports bis zur Annahme der Ware zum Transport kein unbefugter Zugriff
    auf die Ladung stattgefunden hat. Der Versender ist verpflichtet, für eine Verpackung der Sendungen zu sorgen, die der 
    Art der Beilagen der Sendung entspricht.
</p>
<p>
    3. Der Kunde ist verpflichtet, sich bei Auftragserteilung selbstständig mit der Liste gefährlicher, verbotener und
    eingeschränkter Transportgüter vertraut zu machen. Wenn der Kunde Zweifel daran hat, ob seine Ladung in der Liste
    der gefährlichen, verbotenen oder eingeschränkten Transportgüter aufgeführt ist, ist der Kunde verpflichtet, die
    Support-Hotline unter der Telefonnummer +49 40 67555165 oder per E-Mail an order@telega.pro zu kontaktieren
</p>
<p>
    4. Die Kunden stimmen dem Recht von Telega und autorisierten Regierungsbehörden, einschließlich des Zolls, zu, die
    Ladung zu öffnen und zu überprüfen, um das Gesetz einzuhalten oder mögliche Schäden an anderen Ladungen
    auszuschließen, sowie bei Verdacht auf eine inakzeptable oder gefährliche Investition.
</p>
<p>
    5. Anforderungen an zur Lieferung angenommene Sendungen sind in den Standards (Anforderungen) für Gewicht,
    Abmessungen, Verpackung und Etikettierung der Sendungen festgelegt, die im persönlichen Konto des Kunden am Website
    <a href="https://telega.pro" target="_blank">www.telega.pro</a> eingesehen werden können. Gewichtskontrollen,
    Dichtemessungen und Maßmessungen werden bei der Abnahme nicht durchgeführt. Im Sortierzentrum erfolgt das Wiegen,
    Messen der Dichte, Messen der Abmessungen sowie die Überprüfung der Investitionen auf die Möglichkeit des Exports
    aus der Russischen Föderation/Import in das Empfangsland.
</p>
<p>
    5.1. Im Falle einer Diskrepanz zwischen den Daten zu Gewicht und (oder) Abmessungen, die der Kunde bei der
    Erstellung einer Bestellung angegeben hat, und dem tatsächlichen Gewicht und (oder) Abmessungen, die im
    Sortierzentrum festgesetzt sind, werden das physische Gewicht und die Abmessungen vom Sortierzentrum berücksichtigt.
    Gewicht und/oder Abmessungen werden auf den nächsten Wert aufgerundet. Telega behält sich das Recht vor, den vom
    Kunden bezahlten Betrag auf der Grundlage des tatsächlichen Gewichts und der tatsächlichen Abmessungen neu zu
    berechnen und festzulegen, und die Kunde per Brief an die bei der Registrierung seines persönlichen Kontos
    angegebene E-Mail-Adresse darüber informieren(order die Kunde auf andere Weise zu informieren). Der Kunde ist
    verpflichtet, den von Telega gestellten Betrag in Rechnungen zu zahlen, und die Lieferfrist verlängert sich um den
    Zeitpunkt, an dem der Kunde dieser Verpflichtung nachkommt. Verweigert der Kunde die Zahlung des von Telega in
    Rechnung gestellten Betrags auf der Grundlage des im Sortierzentrum festgestellten tatsächlichen Gewichts und (oder)
    der Abmessungen, führt Telega die Maßnahmen mit der Ladung gemäß den Ziffern 6 dieser Offerte durch.
</p>
<p>
    5.2. Ist die Versendung der Ware verboten (Exportzollabfertigung, Importzollabfertigung im Bestimmungsland,
    akzeptable Ware für den Lufttransport etc.), werden damit Maßnahmen gemäß Ziffer 6 dieser Offerte ergriffen.
</p>
<p>
    6. Sofern technisch möglich, legt der Kunde beim Erstellen einer Bestellung in seinem persönlichen Konto fest, was
    mit der Lieferung geschehen soll, wenn Gründe vorliegen, die die Ausführung der Anfrage des Kunden verhindern (z. B.
    wenn sich festgesetzt, dass die Ware nicht zum Versand zugelassen ist; oder hat sich der Kunde geweigert, den auf
    dem Wagen geschuldeten Betrag gemäß dem im Sortierzentrum festgestellten tatsächlichen Gewicht und (oder) den
    tatsächlichen Abmessungen zu zahlen;ist der Kunde mit der Änderung des zu zahlenden Betrags, der sich aufgrund der
    Instabilität der Wirtschaftslage entsteht, nicht einverstanden usw.). Der Kunde kann in seinem persönlichen Konto
    die folgenden Aktionen auswählen, die der Warenkorb mit der Sendung ausführt, wenn die angegebenen Gründe vorliegen:
    Rücksendung an den Absender an der Stelle, an der das Paket zum Versand angenommen wurde (falls möglich);
    Entsorgung.
</p>
<p>
    7. Liegen Gründe vor, die die Ausführung der Anfrage des Kunden verhindern, wenn die Sendung das Abgangsland noch
    nicht verlassen hat und der Kunde im persönlichen Konto die Art der Abwicklung der Sendung gemäß Ziffer 6 gewählt
    hat, führt Telega die aus vom Kunden bei der Erstellung der Bestellung mit der Sendung gewähltes Verfahren –
    Rücksendung oder Entsorgung.
</p>
<p>
    Liegen Gründe vor, die die Ausführung der Anfrage des Kunden verhindern, und wenn die Sendung das Zielland verlassen
    hat, entsorgt Telega die Sendung.
</p>
<p>
    Bei der Rücksendung/Entsorgung einer Sendung hat Telega das Recht, ist jedoch nicht verpflichtet, den Kunden vorab
    über die Rücksendung/Entsorgung einer solchen Sendung zu informieren.
</p>
<p>
    8. Wenn die Sendung beim Export an den Kunden zurückgesendet wird, ist der Kunde verpflichtet, die Rücksendung in
    Höhe der auf der Telega-Website und/oder im persönlichen Konto des Kunden angegebenen Kosten zu bezahlen. Die Fracht
    unterliegt der Entsorgung, wenn der Kunde die Zahlung innerhalb von 30 Tagen nach Erhalt der Benachrichtigung von
    Telega über die Notwendigkeit die Rücksendung, oder nach der Änderung von Status der Sendung für „Bestellung zur
    Rücksendung übertragen“, „erfordert eine Zahlung für die Rücksendung“, nicht gemacht hat (je nachdem, welcher Moment
    zuerst kommt). Beim Export erfolgt die Zahlung für die Rücksendung ausschließlich durch Überweisung auf der
    Grundlage der im persönlichen Konto angegebenen Daten oder der Daten, die dem Kunden per E-Mail vom order@telega.pro
    zugesandt werden.
</p>
<p>
    9. Wenn eine Rücksendung der Sendung nicht möglich ist oder der Kunde bei der Erstellung eines Pakets im
    persönlichen Konto die Entsorgungsoption gewählt hat, wird die Sendung entsorgt. In diesem Fall werden die
    Versandkosten nicht erstattet. Für den Kunden gelten alle Telega Leistungen als ordnungsgemäß erbracht.
</p>
<p>
    10. Telega hat das Recht, die Erbringung von Dienstleistungen für den Kunden zu verweigern oder die Nutzung
    vereinfachter Kommunikationsmittel (über ein Callcenter, elektronisch) zu verweigern, unter anderem aus folgenden
    Gründen:
<ol>
    <li>bei der Anforderung von Leistungen, wenn ein Rückstand für bereits erbrachte Leistungen besteht</li>
    <li>bei der Anforderung von Leistungen, die nicht zu den Standardleistungen des Unternehmens gehören</li>
    <li>bei der Anforderung von Dienstleistungen mit Einzel- oder Vorrangservice</li>
    <li>
        bei Nichteinhaltung der offiziellen Geschäftskommunikation mit Mitarbeitern des Unternehmens, bei Verletzung der
        Rechte von Telega-Mitarbeitern, einschließlich Beleidigung von Mitarbeitern des Unternehmens, Erniedrigung der
        Ehre und Würde von Mitarbeitern des Unternehmens, Verwendung von Schimpfwörtern, Drohungen usw.
    </li>
</ol>
</p>
<p>
    11. Telega ermöglicht die kostenlose Lagerung von Expressfracht für 8 Kalendertage (in einigen Fällen kann sich der
    Zeitraum je nach Lieferpartner verlängern. Genauere Informationen zu den Lagerfristen erhalten Sie, indem Sie sich
    an den Support wenden). Im Einvernehmen der Parteien können die Aufbewahrungsfristen verlängert werden. Nach Ablauf
    der Frachtlagerzeit ist Telega nach eigenem Ermessen berechtigt, die Fracht im eigenen Namen zu entsorgen und/oder
    zu verkaufen. Der Kunde bestätigt, dass er benachrichtigt wurde und stimmt zu, dass im Falle des Verkaufs oder der
    Entsorgung von Expressfracht dem Kunden die Kosten für Expressfracht sowie die Lieferkosten nicht erstattet werden.
</p>
<p>
    12. Telega trägt die Verantwortung für Engpässe, Verluste und Schäden an Sendungen gemäß dem Weltpostvertrag, der
    vom Kongress des Weltpostvereins genehmigt wurde.
</p>
<p>
    13. Entschädigung für Verlust, Mangel oder Beschädigung: 1. Für Artikel mit Barcode RJ - Entschädigung in Höhe von
    30 SDR (jedoch nicht mehr als die Investitionskosten) und die Höhe der Zollgebühr (ohne Bestellgebühr) 2. Für
    Sendungen mit Barcode CJ – Entschädigung in Höhe von 40 DR und 4,5 SDR für jedes Kilogramm Paket (jedoch nicht mehr
    als die Investitionskosten) zuzüglich der Höhe der Zollgebühr. 3. Für Artikel mit dem EJ-Barcode – Entschädigung in
    Höhe von 130 SDR (jedoch nicht mehr als die Investitionskosten) zuzüglich der Höhe der Zollgebühr.
</p>
<p>
    14. Die Zahlung für Dienstleistungen ist eine Bestätigung der Annahme der Bedingungen dieser Offerte.
</p>
<p>
    15. Wenn das Telega-Service Dienstleistungen für den Kauf von Waren erbringt, ist die zuverlässige Bereitstellung
    aller erforderlichen Informationen eine zwingende Voraussetzung für den Kunden. Der Kunde sendet den Managern des
    Services Informationen über das gewünschte Produkt, seine Eigenschaften, die Menge, einen Link zum Produkt im
    Online-Shop, die Kontaktinformationen des Kunden und andere obligatorische Informationen. Dieser Service wurde
    ausschließlich dazu geschaffen, den Kunden zusätzliche Möglichkeiten beim Kauf von Waren in ausländischen
    Online-Shops zu bieten. Der Kunde wählt selbstständig den Verkäufer der Ware und trifft eine Entscheidung. Die
    Bezahlung der Waren erfolgt durch den Kunden an Telega auf eigene Verantwortung gemäß den vom Dienst angegebenen
    Angaben. Für das Produkt kann eine Umtauschgebühr anfallen, die zusätzlich zwischen dem Kunden und dem Dienst
    vereinbart wird. Der Kunde trifft selbstständig Entscheidungen über die Bestellung von Waren, den Kauf über die
    Dienstleistung und andere Bedingungen der Transaktion. Der Kauf von Waren über das Service ist das Recht des Kunden
    und nicht seine Verantwortung. Dem Kunden ist bekannt und er bestätigt, dass Telega nicht an der Kauf- und
    Verkaufstransaktion von Waren zwischen dem Kunden und dem Online-Shop/Marktplatz beteiligt ist. Zu den Aufgaben von
    Telega gehört ausschließlich die Übermittlung von Informationen über die mögliche Bestellung des Kunden und die
    Erbringung von Dienstleistungen zur Lieferung von Gegenständen. Das Telega-Service ist nicht verantwortlich für die
    Qualität des Produkts, seine Eigenschaften, Vollständigkeit oder andere Probleme im Zusammenhang mit dem Produkt
    sowie für seine Lieferung an das Konsolidierungslager von Telega. Die Verpflichtung des Services zur Organisation
    des Versands entsteht erst nach Erhalt des Pakets im Lager von Telega gemäß den Bedingungen dieser Offerte.
</p>
<p>
    16. Der Kunde erklärt sich damit einverstanden, Werbeaktionen zu erhalten. Der Kunde stimmt zu, Werbeaktionen, Sonderangebote 
    und Werbeprodukte aus dem Warenkorb-Service über die angegebenen Kontakte zu erhalten.
</p>
`;
